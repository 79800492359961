import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "panther-athletics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#panther-athletics",
        "aria-label": "panther athletics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther Athletics`}</h3>
    <p>{`Panther Athletics started the year strong with hosting the CEESA Cross Country in Season 1 with over 150 athletes. We co hosted with KIS school. Our biggest cross country team of 35 athletes set a new record for our biggest PSI athletics team ever. Season 1 also saw PSI Panthers win a number of awards with four CEESA championships, two silver awards and one bronze award. KSSL awards were 1 championship and 2 silver awards in the team sports and 16 medals in individual awards - six gold, 5 silver, 5 bronze medals.`}</p>
    <p>{`Season 2 started off well as well with a new record set for our biggest team ever at PSI with 42 athletes and 5 coaches in the swimming team. Basketball awards in KSSL were 2 championships, 1 silver award and 1 bronze award in basketball. First high school boys championship in basketball in 6 years. A new sport with table tennis was introduced. Swim team earned 30 new PSI Swim records and 12 new KSSL swim records and 27 gold, 42 silver and 32 bronze medals. No CEESA or KSSL occured for the rest of 2019 / 2020 due to COVID-19.`}</p>
    <h3 {...{
      "id": "panther-academics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#panther-academics",
        "aria-label": "panther academics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther Academics`}</h3>
    <p>{`The Panther Academics Season started with our students taking part in the Yale Model Government Europe (YMGE) Conference in Lisbon. Our student delegates spent two days in their councils or specialised bodies working on directives to deal with an ever-escalating crisis centered around climate change and artificial intelligence. We witnessed many good debates, loads of collaborative actions, some disappointment, but overall a solid sense of accomplishment. In Season 2, our ‘mathletes’ travelled to Warsaw to compete in the CEESA Middle School MathCounts competition. One of our students made it to the top 5 best competitors. In January, we also hosted the International School Theatre Association (ISTA) festival. The festival brought together young people, artists and teachers from different countries in different settings to experience, create and learn about theatre - all passionately valuing diversity, collaboration, celebration, play and friendship. Sadly, due to COVID -19, PSI could not host the CEESA Middle School Model United Nation conference. No further activities were held in Season 3.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      